/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import "./src/styles/style.scss"

import "./node_modules/bootstrap/scss/bootstrap.scss"
import "./node_modules/react-vertical-timeline-component/style.min.css"
import('./src/pages/index.scss');
